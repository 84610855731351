/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modules/common/top-bar/top-bar.component.ngfactory";
import * as i3 from "./modules/common/top-bar/top-bar.component";
import * as i4 from "@ngrx/store";
import * as i5 from "./modules/auth/auth.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/router";
import * as i8 from "./app.component";
import * as i9 from "@angular/platform-browser";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tiki-top-bar", [["class", "header"]], [[4, "background-color", null]], null, null, i2.View_TopBarComponent_0, i2.RenderType_TopBarComponent)), i1.ɵdid(1, 49152, null, 0, i3.TopBarComponent, [i4.Store, i5.AuthService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).bgColor; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "screen"]], [[2, "screen-auth", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i6.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(3, { "background-color": 0 }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, (!i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.isSignedIn$)) ? _co.backgroundColor : "#f2f1f1")); _ck(_v, 1, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.isSignedIn$)); _ck(_v, 6, 0, currVal_2); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 4).transform(_co.isSignedIn$)); _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tiki-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i8.AppComponent, [i4.Store, i1.ElementRef, i9.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("tiki-app", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
