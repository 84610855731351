import { Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import * as fromStore from '@store/auth';
import { Store } from '@ngrx/store';

import { AuthState } from '@app/store/auth';
import { AuthService } from '@modules/auth';
import { Branches } from '@app/enums';

@Component({
  selector: 'tiki-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
  @HostBinding('style.background-color') bgColor = '#f2a948';
  logo: string;
  backgroundColor: string;
  branchID: string;
  readonly userRole: string = '';

  constructor(private store: Store<AuthState>,
              public auth: AuthService) {

    const jwt = this.parseJwt(auth.getToken());
    if (jwt && jwt.role) {
      this.userRole = jwt.role;
    }

    try {
      this.branchID = $ENV_OUTER.BRANCH_ID;
    } catch (error) {
      this.branchID = '5';
    }
    switch (this.branchID) {
      case Branches.TIKIEN:
        this.logo = 'en';
        this.backgroundColor = '#cc0033';
        break;
      case Branches.TIKISO:
        this.backgroundColor = '#99cc33';
        this.logo = 'so';
        break;
      case Branches.TIKIMA:
        this.logo = 'ma';
        this.backgroundColor = '#66ccff';
        break;
      case Branches.TIKIPL:
        this.logo = 'pl';
        this.backgroundColor = '#990099';
        break;
      case Branches.TIKINO:
        this.logo = 'no';
        this.backgroundColor = '#f2a948';
        break;
      case Branches.TIKISE:
        this.logo = 'se';
        this.backgroundColor = '#cfdb06';
        break;
      default:
        this.logo = 'no';
        this.backgroundColor = '#f2a948';
        break;
    }
    this.bgColor = this.backgroundColor;
  }

  signOut(): void {
    this.store.dispatch(new fromStore.SignOut());
  }

  private parseJwt(token: string) {
    if (token && token.includes('.')) {
      const base64Url: string = token.split('.')[1];
      const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload: string = decodeURIComponent(window.atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
    return null;
  }
}
